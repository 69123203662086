import React from "react"
import { graphql } from "gatsby"

import { Col } from "../components/utilities"
import { Banner, Section, SectionGrid, Signature } from "../components/elements"
import { Layout, FullImage } from "../components/layouts/Layout"

const GiftCertificatePage = ({ data }) => {
  const { salon } = data
  return (
    <Layout title="Gift Certificate">
      <Banner title="gift certificate" />
      <Section>
        <SectionGrid>
          <Col>
            <h3>Gift Certificate</h3>
            <p>
              We offer gift certificates at our salon! For a wrapped gift card,
              visit us in person or purchase online. They make perfect presents
              for birthdays, anniversaries, or holidays. Consider giving one for
              Mother's Day or as a thoughtful thank-you gesture. Visit us today
              or{" "}
              <a
                target="_blank"
                href="https://squareup.com/gift/7F17SMDX3Y685/order"
                rel="noreferrer noopener"
              >
                order online
              </a>
              !
            </p>
            <br />
            <Signature />
          </Col>
          <Col>
            <FullImage picture={salon?.childImageSharp?.fluid?.src} />
          </Col>
        </SectionGrid>
      </Section>
    </Layout>
  )
}

export default GiftCertificatePage

export const query = graphql`
  query GiftCertificateQuery {
    salon: file(relativePath: { eq: "Gift-Card.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          src
        }
      }
    }
  }
`
